import AudioService from './index';

const ctx = new WeakMap();
const stream = new WeakMap();

export default class AudioMic extends AudioService {
  constructor({ desiredSampleRate } = {}) {
    const AvailableAudioContext = window.AudioContext || window.webkitAudioContext;
    const context = new AvailableAudioContext();
    super({ context, desiredSampleRate });

    ctx.set(this, context);
  }

  enable = async () => {
    const context = ctx.get(this);
    const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaSource = context.createMediaStreamSource(mediaStream);

    super.enable({ mediaSource });

    ctx.set(this, context);
    stream.set(this, mediaStream);
  }

  disable = () => {
    const mediaStream = stream.get(this);
    const tracks = mediaStream?.getTracks();

    if (mediaStream && tracks && tracks.length > 0) tracks.forEach((track) => track.stop());

    super.disable();
    ctx.set(this, null);
  }
}

<template>
  <button
    v-loading="isLoading"
    :disabled="isLoading"
    :class="{ isActive }"
    class="audio-input"
    @click="handleClick"
  >
    <img
      :src="isActive ? svg.MicOn : svg.MicOff"
      alt="mic"
      class="audio-input__icon"
    >
    <slot />
  </button>
</template>

<script>
import AudioService from '@/service/audio/mic';
import ErrorService from '@/service/utils/error';
import MicOff from '@/assets/images/icons/mic-off.svg';
import MicOn from '@/assets/images/icons/mic-on.svg';

export default {
  props: {
    onPreEnable: {
      type: Function,
      default: null,
    },
    onEnable: {
      type: Function,
      default: null,
    },
    onDisable: {
      type: Function,
      default: null,
    },
    onStream: {
      type: Function,
      default: null,
    },
    desiredSampleRate: {
      type: Number,
      default: 16000,
    },
    icons: {
      type: Object,
      default: () => ({ on: '', off: '' }),
    },
  },
  data() {
    return {
      isActive: false,
      isLoading: false,
      audioService: null,
    };
  },
  computed: {
    svg() {
      return { MicOff: this.icons.off || MicOff, MicOn: this.icons.on || MicOn };
    },
  },
  async beforeDestroy() {
    if (!this.isActive) return;

    await this.audioService.disableMic();
  },
  methods: {
    async handleClick() {
      this.$emit('click');

      const {
        isActive,
        disableMic,
        onPreEnable,
        enableMic,
        desiredSampleRate,
      } = this;

      if (isActive) return disableMic();

      if (this.isLoading) throw new Error('Enabling the mic is already in progress');

      this.isLoading = true;
      let succeeded = false;

      try {
        if (this.onPreEnable) succeeded = await onPreEnable(disableMic);
      } catch (ex) {
        ErrorService.logRuntimeError(ex);
      } finally {
        this.isLoading = false;
      }

      if (!succeeded) return null;

      return enableMic({ desiredSampleRate });
    },
    async enableMic(options) {
      const { onEnable } = this.$props;

      if (this.isActive) return;

      const audioService = new AudioService(options);
      audioService.onStream = this.onStream;
      await audioService.enable(options);

      this.isActive = true;
      this.audioService = audioService;

      if (onEnable) onEnable();
    },
    disableMic(fromSideEffect = false) {
      if (!this.isActive) return;

      this.isActive = false;

      const { onDisable } = this.$props;

      const output = this.audioService?.disable();

      if (onDisable && !fromSideEffect) onDisable(output);

      this.audioService = null;
    },
  },
};
</script>

<style lang="scss" scoped>
$icon-size: 0.46rem;

.audio-input {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: $button-white-gradient;

  &__icon {
    width: $icon-size;
    height: $icon-size;
  }
}
</style>

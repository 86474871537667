<template>
  <div class="audio-stream">
    <audio-input
      class="audio-stream__input"
      :icons="icons"
      :on-pre-enable="handlePreEnable"
      :on-disable="handleConnectionClose"
      :on-stream="handleStream"
      :desired-sample-rate="sampleRate"
      @click="$emit('click')"
    >
      <slot />
    </audio-input>
    <audio-file-input
      v-if="showFileUpload"
      class="audio-stream__input"
      :allowed-formats="allowedFormats"
      :on-upload="handleFileChange"
      :is-active="fileInputActive"
      :disabled="fileInputDisable"
      :max-size="fileMaxSize"
      @error="onFileInputError"
    >
      <slot />
    </audio-file-input>
    <audio-stream-bar
      v-if="fileInputActive"
      :messages="audioFileStatus.messages"
      :audio-time="audioFileStatus.duration"
      :current-time="audioFileStatus.currentTime"
    />
  </div>
</template>

<script>
import { constants } from '@/libs/constants';
import AudioFile from '@/service/audio/file';
import ErrorService from '@/service/utils/error';
import { isSafari, isIE } from '@/libs/utils';
import AudioInput from '@/components/AudioInput/Index.vue';
import AudioFileInput from '@/components/AudioFileInput/Index.vue';
import AutomaticSpeechRecognition from '@/service/api/asa/automaticSpeechRecognition';
import asrResponseMixin from './response.mixin';
import AudioStreamBar from './AudioStreamBar.vue';

export default {
  components: {
    AudioInput,
    AudioFileInput,
    AudioStreamBar,
  },
  mixins: [asrResponseMixin],
  props: {
    onOpen: {
      type: Function,
      default: null,
    },
    onResponse: {
      type: Function,
      required: true,
    },
    lang: {
      type: String,
      default: constants.lang.EN,
    },
    raw: {
      type: Boolean,
      default: false,
    },
    showFileUpload: {
      type: Boolean,
      default: false,
    },
    fileMaxSize: {
      type: Number,
      default: 10,
    },
    icons: {
      type: Object,
      default: () => ({ on: '', off: '' }),
    },
  },
  data() {
    return {
      asr: null,
      audio: null,
      sideEffectBeforeDestroy: null,
      fileInputActive: false,
      fileInputDisable: false,
      audioFileStatus: {
        messages: [],
        duration: 0,
        currentTime: 0,
      },
    };
  },
  computed: {
    allowedFormats() {
      return this.$consts.config.ASR.ALLOWED_FORMATS.join(',');
    },
    sampleRate() {
      if (!this.asr) return AutomaticSpeechRecognition.defaultSampleRate;

      return this.asr.sampleRate;
    },
  },
  watch: {
    lang() {
      this.handleConnectionClose();
    },
  },
  beforeDestroy() {
    this.handleConnectionClose();
  },
  methods: {
    handleStream(e, buffer) {
      if (!this.asr) return;

      this.asr.sendAudioData(e, buffer);
    },
    async handlePreEnable(disableMic) {
      if (!navigator.mediaDevices) return ErrorService.displayErrorAlert('Please use https services');
      this.fileInputDisable = true;
      this.clearPreviousStream();

      const { onOpen, handleResponse } = this;
      const asrOptions = { lang: this.$props.lang, onResponse: handleResponse, onOpen };
      const asr = new AutomaticSpeechRecognition(asrOptions);
      asr.setConfig({ lang: this.$props.lang });

      try {
        asr.start();
        await asr.onReady();
      } catch (ex) {
        ErrorService.displayErrorAlert('Unable to make connection to the server');
        return false;
      }
      this.asr = asr;
      this.sideEffectBeforeDestroy = disableMic;

      return true;
    },

    clearPreviousStream() {
      if (this.asr) this.asr.end();
      if (this.audio) this.audio.disable();

      this.resetASR();
      this.asr = null;
      this.audio = null;
      this.fileInputActive = false;
      this.audioFileStatus = {
        messages: [],
        duration: 0,
        currentTime: 0,
      };
    },

    async handleFileChange(file) {
      if (isSafari || isIE) return ErrorService.displayInfoAlert('If you\'d like to maximize the usage of the features in our Demo, we suggest you to use browsers other than IE and Safari');

      if (this.audio) {
        this.clearPreviousStream();
      }
      this.fileInputActive = true;

      const { onOpen, handleResponse } = this;
      const asr = new AutomaticSpeechRecognition({ lang: this.$props.lang, onResponse: handleResponse, onOpen });
      asr.setConfig({ lang: this.$props.lang });

      try {
        asr.start();
        await asr.onReady();
      } catch (ex) {
        ErrorService.displayErrorAlert('Unable to make connection to the server');
        return null;
      }

      const audio = new AudioFile({ desiredSampleRate: asr.sampleRate, blob: file });

      audio.onStream = asr.sendAudioData;
      audio.onEnded = () => {
        this.fileInputActive = false;
      };

      this.audioFileStatus.currentTime = 0;

      audio.currentTimeTicks = (sec, duration) => {
        if (this.audioFileStatus.currentTime !== sec) {
          this.audioFileStatus.currentTime = sec;
          this.audioFileStatus.duration = duration;
        }
      };

      audio.play();
      this.audioFileStatus.messages = [{
        msg: 'Audio file uploaded successfully.',
      }];

      this.asr = asr;
      this.audio = audio;
      this.isLoading = false;
      return null;
    },
    handleResponse(data) {
      if (!this.asr) return null;

      if (this.raw) return this.onResponse(data);

      const message = this.handleResponseASR(data);

      if (!message.trim()) return null;

      if (this.audioFileStatus.messages.length < 2) {
        this.audioFileStatus.messages.push({
          msg: 'Translation in Progress',
          type: 'primary',
        });
      }

      return this.onResponse(message);
    },
    handleConnectionClose() {
      this.fileInputDisable = false;

      if (!this.asr) return;

      if (this.sideEffectBeforeDestroy) this.sideEffectBeforeDestroy({ fromSideEffect: true });

      this.resetASR();
      this.asr.end();
      this.asr = null;
      this.sideEffectBeforeDestroy = null;
    },

    onFileInputError(er) {
      this.$emit('error', er);
    },
  },
};
</script>

<style lang="scss" scoped>
.audio-stream {
  display: flex;

  &__input {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;

    &:not(:last-child) {
      margin-right: .15rem;
    }
  }
}

</style>

const sliceComma = (string) => string.slice(2);

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const addSpaceConcat = (currentMessage, toAdd) => {
  if (!toAdd) return currentMessage;

  if (!currentMessage) return toAdd[0] === ',' ? capitalize(sliceComma(toAdd)) : capitalize(toAdd);

  if (toAdd[0] === '.' || toAdd[0] === ',' || toAdd[0] === ' ' || currentMessage[currentMessage.length - 1] === ' ') return `${currentMessage}${toAdd}`;

  return `${currentMessage} ${toAdd}`;
};

const RESPONSE_TYPE = {
  PARTIAL: 'partial',
  COMPLETE: 'complete',
};

export default {
  data: () => ({
    asrMixin: {
      message: '',
      finalMessage: '',
    },
  }),
  methods: {
    resetASR() {
      this.asrMixin = {
        ...this.asrMixin,
        message: '',
        finalMessage: '',
      };
    },
    handleResponseASR(data) {
      if (!data || !data.text) return '';

      const transcription = data.text;
      const { finalMessage } = this.asrMixin;
      const result = addSpaceConcat(finalMessage, transcription);
      this.asrMixin = {
        ...this.asrMixin,
        message: result,
        finalMessage: data.type === RESPONSE_TYPE.PARTIAL ? finalMessage : result,
      };

      return result;
    },
  },
};

<template>
  <div class="stream-bar">
    <p class="stream-bar-message">
      <span
        v-for="(msg, index) in messages"
        :key="index"
        :class="msg.type"
      >{{
        msg.msg
      }}</span>
    </p>

    <div class="stream-bar-status">
      <div
        class="stream-bar-status-indicator"
        :style="value"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AudioStreamBar',

  props: {
    audioTime: {
      type: Number,
      default: 0,
      required: true,
    },
    currentTime: {
      type: Number,
      default: 0,
      required: true,
    },
    messages: {
      type: Array,
      required: true,
      default: () => [
        {
          msg: 'Translation is in process',
          type: 'primary',
        },
      ],
    },
  },
  computed: {
    value() {
      return {
        width: `${(100 * this.currentTime) / this.audioTime}%`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.stream-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;

  &-message {
    color: $text-primary;
    font-weight: 400;
    font-size: 0.11rem;
    line-height: 0.12rem;

    span {
      padding-right: 0.04rem;
    }

    .primary {
      color: #4a67ff;
    }
  }

  &-status {
    background: #d0e5f4;
    border-radius: 51px;
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0.1rem;
    margin-top: 0.06rem;

    &-indicator {
      background: linear-gradient(91.08deg, #4a67ff 11.92%, #0ab4ff 100%);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      transition: width .2s;
    }
  }
}
</style>

import { readAsArrayBufferAsync } from '@/libs/utils';
import AudioService from './index';

const ctx = new WeakMap();
const source = new WeakMap();
const isPaused = new WeakMap();

export const AUDIO_PROCESSING_TIME_DELAY_MS = 1000;

export default class AudioFile extends AudioService {
  constructor({ context: audioContext, desiredSampleRate, blob } = {}) {
    const AvailableAudioContext = window.AudioContext || window.webkitAudioContext;
    const context = audioContext || new AvailableAudioContext();
    super({ context, desiredSampleRate });
    this.blob = blob;
    ctx.set(this, context);
  }

  blob = null;

  onEnded = null;

  handleEnded = () => {
    if (this.isPaused) return;

    setTimeout(() => {
      super.disable();
      if (this.onEnded) this.onEnded();
    }, AUDIO_PROCESSING_TIME_DELAY_MS);
  }

  play = async () => {
    const context = ctx.get(this);
    const arrayBuffer = await readAsArrayBufferAsync(this.blob);
    const buffer = await context.decodeAudioData(arrayBuffer);
    const mediaSource = context.createBufferSource();
    mediaSource.buffer = buffer;
    mediaSource.onended = this.handleEnded;
    super.enable({ mediaSource });
    if (mediaSource.noteOn) {
      mediaSource.noteOn(0, super.currentTime);
    } else if (mediaSource.play) {
      mediaSource.play(0, super.currentTime);
    } else if (mediaSource.start) {
      mediaSource.start(0, super.currentTime);
    }
    source.set(this, mediaSource);
    isPaused.set(this, false);
  }

  pause = () => {
    const mediaSource = source.get(this);

    if (!mediaSource) return;

    isPaused.set(this, true);

    setTimeout(() => {
      if (mediaSource.noteOff) {
        mediaSource.noteOff();
      } else if (mediaSource.pause) {
        mediaSource.pause();
      } else if (mediaSource.stop) {
        mediaSource.stop();
      }
      super.disconnect();
    }, AUDIO_PROCESSING_TIME_DELAY_MS);
  }

  get isPaused() {
    return isPaused.get(this);
  }
}

<template>
  <el-upload
    v-loading="isLoading"
    :disabled="isLoading || disabled"
    :class="{ isActive }"
    class="audio-input"
    :http-request="httpFunction"
    :before-upload="beforeFileUpload"
    :accept="allowedFormats"
    :auto-upload="true"
    action="#"
  >
    <img
      :src="isActive ? svg.MicOn : svg.MicOff"
      alt="upload"
      class="audio-input__icon"
    >
    <slot />
  </el-upload>
</template>

<script>
import FileInputOff from '@/assets/images/icons/file-input-off.svg';
import FileInputOn from '@/assets/images/icons/file-input-on.svg';

const RENDER_LOADING_FIRST = 300;

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    dataUrl: {
      type: Boolean,
      default: false,
    },
    onUpload: {
      type: Function,
      default: () => {},
    },
    allowedFormats: {
      type: String,
      default: '',
    },
    maxSize: {
      type: Number,
      default: 10,
    },
    icons: {
      type: Object,
      default: () => ({ on: '', off: '' }),
    },
  },
  data() {
    return {
      isLoading: false,
      audioService: null,
    };
  },
  computed: {
    svg() {
      return { MicOff: this.icons.off || FileInputOff, MicOn: this.icons.on || FileInputOn };
    },
  },
  methods: {
    httpFunction(request) {
      if (!this.dataUrl) return this.onUpload(request.file);

      this.isLoading = true;

      return setTimeout(() => {
        const reader = new FileReader();

        reader.onload = () => {
          this.onUpload(request.file, reader.result);
          this.isLoading = false;
        };

        reader.readAsDataURL(request.file);
      }, RENDER_LOADING_FIRST);
    },
    beforeFileUpload(file) {
      const isValidSize = file.size / 1024 / 1024 < this.maxSize;

      if (!isValidSize) {
        this.$emit('error', { type: 'error', message: 'File size can not exceed 10MB!' });
      }
      return isValidSize;
    },
  },
};
</script>

<style lang="scss">
$icon-size: 0.46rem;

.audio-input {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: $button-white-gradient;

  .el-upload-list {
    display: none;
  }

  &__icon {
    width: $icon-size;
    height: $icon-size;
  }
}
</style>

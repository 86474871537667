import Resampler, { floatTo16BitPCM } from '@/libs/resampler';

const recorder = new WeakMap();
const ctx = new WeakMap();
const sampler = new WeakMap();
const config = new WeakMap();
const raw = new WeakMap();
const buffer = new WeakMap();
const output = new WeakMap();
const source = new WeakMap();
const time = new WeakMap();

const BIT_DEPTH = 32;
const BITS_PER_BYTE = 8;
const FLOAT_POSITION = 4;
const MULTIPLIER = 0.1;

const computeBufferSizeThreshold = (desiredSampleRate) => {
  if (!desiredSampleRate) throw new Error('Sample Rate cannot be falsy!');

  return (((desiredSampleRate * BIT_DEPTH) / BITS_PER_BYTE) / FLOAT_POSITION) * MULTIPLIER;
};

const generateConfig = ({
  outputSampleRate,
  CHANNEL_NUM = 1,
  BUFFER_SIZE = 1024,
} = {}) => ({
  outputSampleRate,
  CHANNEL_NUM,
  BUFFER_SIZE,
  BUFFER_SIZE_THRESHOLD: computeBufferSizeThreshold(outputSampleRate),
});

export default class AudioRecorderService {
  constructor({ context, desiredSampleRate, rawBufferOnDisable }) {
    if (!context) throw new Error('Context cannot be empty!');

    this.rawBufferOnDisable = rawBufferOnDisable;
    const outputSampleRate = desiredSampleRate || context.sampleRate;
    const audioConfig = generateConfig({ outputSampleRate });
    const resampler = new Resampler(
      context.sampleRate,
      audioConfig.outputSampleRate,
      audioConfig.CHANNEL_NUM,
      audioConfig.BUFFER_SIZE,
    );

    time.set(this, 0);
    sampler.set(this, resampler);
    ctx.set(this, context);
    config.set(this, audioConfig);
    buffer.set(this, []);
    output.set(this, []);
    raw.set(this, []);
  }

  rawBufferOnDisable = false;

  enable({ mediaSource } = {}) {
    const audioContext = ctx.get(this);
    const audioConfig = config.get(this);
    const { CHANNEL_NUM, BUFFER_SIZE } = audioConfig;
    const mediaRecorder = audioContext.createScriptProcessor(BUFFER_SIZE, CHANNEL_NUM, CHANNEL_NUM);
    mediaRecorder.onaudioprocess = this.handleAudioProcess;
    mediaSource.connect(mediaRecorder);
    mediaRecorder.connect(audioContext.destination);

    source.set(this, mediaSource);
    recorder.set(this, mediaRecorder);
  }

  disconnect() {
    const mediaSource = source.get(this);
    const mediaRecorder = recorder.get(this);
    if (mediaSource) mediaSource.disconnect(mediaRecorder);
    if (mediaRecorder) mediaRecorder.disconnect(ctx.get(this).destination);

    source.set(this, null);
    recorder.set(this, null);
  }

  disable() {
    time.set(this, 0);
    this.disconnect();
    const finalOutput = output.get(this);

    recorder.set(this, null);
    buffer.set(this, []);
    output.set(this, []);
    raw.set(this, []);
    ctx.set(this, null);
    sampler.set(this, null);
    config.set(this, null);

    return finalOutput;
  }

  onStream = null;

  handleAudioProcess = (event) => {
    time.set(this, event.playbackTime);

    if (!sampler.has(this)) return this.disable();

    this.currentTimeTicks(Math.trunc(event.playbackTime) || 0, source.get(this).buffer?.duration);

    const resampler = sampler.get(this);
    const leftChannelBuffer = event.inputBuffer.getChannelData(0);
    const rawBuffer = raw.get(this);
    const totalBuffer = output.get(this);

    if (this.rawBufferOnDisable) rawBuffer.push(...leftChannelBuffer);

    const resampledBuffer = resampler.resample(leftChannelBuffer);
    const currentBuffer = buffer.get(this);
    currentBuffer.push(...resampledBuffer);

    if (!this.rawBufferOnDisable) totalBuffer.push(...resampledBuffer);

    raw.set(this, rawBuffer);
    output.set(this, totalBuffer);
    buffer.set(this, currentBuffer);
    const audioConfig = config.get(this);

    if (!this.onStream || currentBuffer.length < audioConfig.BUFFER_SIZE_THRESHOLD) return null;

    const bufferToPush = floatTo16BitPCM(currentBuffer);
    this.onStream(event, bufferToPush);
    return buffer.set(this, []);
  }

  get currentTime() {
    return time.get(this);
  }

  currentTimeTicks = () => {};
}
